<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7_22442)">
        <path d="M2.5 10.6307C2.5 11.6157 2.69399 12.5909 3.0709 13.5009C3.44781 14.4108 4.00026 15.2376 4.6967 15.934C5.39314 16.6305 6.21993 17.1829 7.12987 17.5598C8.03982 17.9367 9.01509 18.1307 10 18.1307C10.9849 18.1307 11.9602 17.9367 12.8701 17.5598C13.7801 17.1829 14.6069 16.6305 15.3033 15.934C15.9997 15.2376 16.5522 14.4108 16.9291 13.5009C17.306 12.5909 17.5 11.6157 17.5 10.6307C17.5 8.64161 16.7098 6.73396 15.3033 5.32744C13.8968 3.92091 11.9891 3.13074 10 3.13074C8.01088 3.13074 6.10322 3.92091 4.6967 5.32744C3.29018 6.73396 2.5 8.64161 2.5 10.6307Z" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 6.46405V10.6307L12.5 13.1307" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_7_22442">
        <rect width="20" height="20" fill="white" transform="translate(0 0.630737)"/>
        </clipPath>
        </defs>
    </svg>
</template>