<template>
  <el-collapse v-model="activeNames" @change="handleChange" class="collapse-wrapper">
    <el-collapse-item class="" v-for="(question, index) in selectedQuestion.questions" :key="question.id"
      :title="'Question ' + (index + 1)">

      <div class="mt-5">
        <div class="flex flex-row mb-5">
          <div v-if="question.image != null">
            <el-image class="rounded-lg sm:ml-2 mr-2" style="width: 100px; height: 100px"
              :src="question.image.toString()" :preview-src-list="[question.image.toString()]">
            </el-image>
          </div>
          <div
            class="w-full">
            <!--<div class="border-b px-2 py-1">Question</div>-->
            <div class="select-none br">
              <span class="question-title">{{ question.question }}</span>
            </div>
          </div>
        </div>

        <div v-if="question.type == 'qcm' || question.type == 'qcs'">
          <div>
            <div class="w-full" v-for="(proposition, key) in question.propositions" :key="proposition.id" @click="
              selectSuppositions(
                {
                  questionId: question.id,
                  questionType: question.type,
                  suppositionId: proposition.id,
                },
                null
              )
              ">
              <div class="quiz-section__propositions-item flex justify-between items-center" :class="showResponse &&
                  verifiedSelectedQuestion(question.id, proposition.id) &&
                  proposition.state == '1'
                  ? 'correct'
                  : showResponse &&
                    verifiedSelectedQuestion(question.id, proposition.id) &&
                    proposition.state == '0'
                    ? 'incorrect'
                    : showResponse &&
                      !verifiedSelectedQuestion(question.id, proposition.id) &&
                      proposition.state == '1'
                      ? 'correct'
                      : verifiedSelectedQuestion(question.id, proposition.id)
                        ? 'bg-gray-300'
                        : ''
                ">
                <div class="select-none flex flex-row w-full justify-between">
                  <div>
                    <span class="quiz-section__propositions-item-label"
                      :class="verifiedSelectedQuestion(question.id, proposition.id) ? 'selected' : ''">{{
                        String.fromCharCode(key + 65)
                      }}</span>
                    &nbsp;
                  </div>
                  <div class="flex-1">
                    <span class="quiz-section__propositions-item-proposal">{{ proposition.proposal }}</span>
                  </div>
                  <div>
                    <div v-if="
                      showResponse &&
                      verifiedSelectedQuestion(question.id, proposition.id) &&
                      proposition.state == '1'
                    ">
                      <!--<el-icon color="#34D399" :size="15" class="mr-2">
                        <CircleCheckFilled />
                      </el-icon>-->
                      <CircleCheckIcon />
                    </div>
                    <div v-if="
                      showResponse &&
                      verifiedSelectedQuestion(question.id, proposition.id) &&
                      proposition.state == '0'
                    ">
                      <!--<el-icon color="#F87171" :size="15" class="mr-2">
                        <CircleCloseFilled />
                      </el-icon>-->
                      <CircleTimesIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="question.type == 'qroc'">
          <button v-if="!showQrocResponse[index]" @click="showQrocResponse[index] = true" class="w-full">
            <el-icon><arrow-down-bold /></el-icon>
          </button>
          <button v-if="showQrocResponse[index]" @click="showQrocResponse[index] = false" class="w-full">
            <el-icon><arrow-up-bold /></el-icon>
          </button>
          <div v-if="showQrocResponse[index] == true">
            <div class="p-2 bg-gray-100 rounded-lg select-none br">
              {{ question.response }}
            </div>
            <div class="flex justify-center mt-4 mb-1" v-if="qrocResponse[index] == null">
              <button @click="
                selectSuppositions(
                  {
                    questionId: question.id,
                    questionType: question.type,
                    suppositionId: 'true',
                  },
                  { index: index, state: 'true' }
                )
                " class="
                  flex
                  items-center
                  justify-center
                  py-2
                  px-5
                  mx-1
                  sm:my-0
                  my-1
                  text-white
                  font-normal
                  text-sm
                  bg-green-400
                  rounded-lg
                  hover:shadow-lg
                  transition
                  duration-500
                  transform-gpu
                  hover:scale-110
                ">
                <el-icon color="#fff" :size="15" class="mr-2">
                  <check />
                </el-icon>
                Correcte
              </button>
              <button @click="
                selectSuppositions(
                  {
                    questionId: question.id,
                    questionType: question.type,
                    suppositionId: 'false',
                  },
                  { index: index, state: 'false' }
                )
                " class="
                  flex
                  items-center
                  justify-center
                  py-2
                  px-7
                  mx-1
                  sm:my-0
                  my-1
                  text-white
                  font-normal
                  text-sm
                  bg-red-400
                  rounded-lg
                  hover:shadow-lg
                  transition
                  duration-500
                  transform-gpu
                  hover:scale-110
                ">
                <el-icon color="#fff" :size="15" class="mr-2">
                  <close />
                </el-icon>
                Fausse
              </button>
            </div>
            <div class="flex justify-center mt-4 mb-1" v-if="qrocResponse[index] != null">
              <p v-if="qrocResponse[index] == true" class="
                  p-2
                  mx-1
                  bg-green-200
                  border border-green-500
                  text-green-500
                  rounded-lg
                ">
                <el-icon color="#10B981" :size="15" class="mr-2">
                  <check />
                </el-icon>
                Réponse correte
              </p>
              <p v-if="qrocResponse[index] == false" class="
                  p-2
                  mx-1
                  bg-red-200
                  border border-red-500
                  text-red-500
                  rounded-lg
                ">
                <el-icon color="#EF4444" :size="15" class="mr-2">
                  <close />
                </el-icon>
                Réponse fausse
              </p>
            </div>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import {
  Check,
  Close,
  /*CircleCloseFilled,
  CircleCheckFilled,*/
  ArrowDownBold,
  ArrowUpBold,
} from "@element-plus/icons";
import CircleCheckIcon from "../icons/CircleCheckIcon.vue";
import CircleTimesIcon from "../icons/CircleTimesIcon.vue";
export default {
  components: {
    Check,
    Close,
    //CircleCloseFilled,
    //CircleCheckFilled,
    ArrowDownBold,
    ArrowUpBold,
    CircleCheckIcon,
    CircleTimesIcon
  },

  props: {
    selectedQuestion: Object,
    OldselectedAnswer: Array,
    showResponse: Boolean,
    OldqrocResponse: Array,
    show: Array,
    dark: Boolean,
  },

  data() {
    return {
      selectedAnswer: [],
      showQrocResponse: [],
      qrocResponse: [],
    };
  },

  mounted() {
    this.selectedAnswer = this.OldselectedAnswer;
    this.qrocResponse = this.OldqrocResponse;
    this.showQrocResponse = this.show;
  },
  methods: {
    selectSuppositions(index, reponseQroc) {
      if (!this.showResponse) {
        if (index.questionType == "qcm" || index.questionType == "qcs") {
          var verified = false;
          var lastSelectedQuestionId = null;
          this.selectedAnswer.forEach((element) => {
            if (element.questionId == index.questionId) {
              verified = true;
              var suppExist = false;
              element.suppositionsId.forEach((supposition) => {
                if (supposition == index.suppositionId) {
                  suppExist = true;
                  if (element.suppositionsId.length > 1) {
                    element.suppositionsId = element.suppositionsId.filter(
                      (supp) => supp !== index.suppositionId
                    );
                  } else {
                    lastSelectedQuestionId = element.questionId;
                  }
                }
              });
              if (!suppExist) {
                element.suppositionsId = [
                  ...element.suppositionsId,
                  index.suppositionId,
                ];
              }
            }
          });

          if (!verified) {
            this.selectedAnswer = [
              ...this.selectedAnswer,
              {
                questionId: index.questionId,
                questionType: index.questionType,
                suppositionsId: [index.suppositionId],
              },
            ];
          }
          if (lastSelectedQuestionId != null) {
            this.selectedAnswer = this.selectedAnswer.filter(
              (element) => element.questionId !== lastSelectedQuestionId
            );
          }
        } else {
          // qroc
          this.qrocResponse[reponseQroc.index] = reponseQroc.state === "true";
          this.selectedAnswer = [
            ...this.selectedAnswer,
            {
              questionId: index.questionId,
              questionType: index.questionType,
              state: index.suppositionId,
            },
          ];
        }
      }
      this.$emit("selected_answer", this.selectedAnswer);
    },

    verifiedSelectedQuestion(questionid, suppositionid) {
      var verified = false;
      this.selectedAnswer.forEach((question) => {
        if (question.questionId == questionid) {
          question.suppositionsId.forEach((supposition) => {
            if (supposition == suppositionid) {
              verified = true;
            }
          });
        }
      });
      return verified;
    },
  },
  watch: {
    selectedQuestion: function () {
      this.selectedAnswer = this.OldselectedAnswer;
      this.qrocResponse = this.OldqrocResponse;
      this.showQrocResponse = this.show;
    },
  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  },
};
</script>

<style></style>