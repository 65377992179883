<template>
<div class="exit-session-container">
  <el-dialog v-model="showExitConfirmation" title="Quitter la série" :width="dialogWidth" center :before-close="closeModal" destroy-on-close>
       <el-result
            v-if="!isEndSession"
            icon="warning"
            title="Attention"
            subTitle="Voulez-vous vraiment quitter cette série ?"
        >
        </el-result>
        <div v-else class="w-full flex justify-center flex-col gap-6">
            <div class="flex justify-center">
              <!--<p v-if="NoteSession != null" class="text-lg text-center w-full">La note est: <span class="font-bold">{{ NoteSession.datasets[0].data[0].toFixed(2)}}/{{ Math.round( NoteSession.datasets[0].data[0] + NoteSession.datasets[0].data[1] + NoteSession.datasets[0].data[2] )}}</span></p>-->
              <Chart class="w-70" v-if="NoteSession != null" type="doughnut" :data="NoteSession"/>
            </div>
            <div class="flex items-center gap-4"  v-if="NoteSession != null">
              <div class="result-block flex-1">
                <p class="result-block__title">La note est :</p>
                <p class="result-block__value">{{ NoteSession.datasets[0].data[0].toFixed(2)}}/{{ Math.round( NoteSession.datasets[0].data[0] + NoteSession.datasets[0].data[1] + NoteSession.datasets[0].data[2] )}}</p>
              </div>
              <!--<div class="result-block flex-1">
                <p class="result-block__title">Votre précision :</p>
                <p class="result-block__value">{{ NoteSession.datasets[0].data[0].toFixed(2)}}/{{ Math.round( NoteSession.datasets[0].data[0] + NoteSession.datasets[0].data[1] + NoteSession.datasets[0].data[2] )}}</p>
              </div>-->
            </div>
        </div>

    <template #footer>
      <span class="dialog-footer flex items-center gap-4">
        <button @click="closeModal" class="secondary-btn py-4 flex-1"> 
          Annuler
        </button>
        <button @click="confirmeExitConfirmationSession" class="primary-btn py-4 flex-1"> 
          Confirmer
        </button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>
import Chart from 'primevue/chart';
export default {
  components: {
    Chart
  },

  data () {
    return {
      dialogWidth: this.getDialogWidth()       
    }
  },
  
  props:{
    showExitConfirmation : Boolean,
    closeExitConfirmationSession: Function,
    confirmeExitConfirmationSession : Function,
    isEndSession:Boolean,
    NoteSession: Object,
  },

  methods: {
    closeModal(){
      this.closeExitConfirmationSession()
    },
    confirmeModal(){
      this.confirmeExitConfirmationSession()
    },
    getDialogWidth() {
      return window.innerWidth <= 768 && window.innerWidth > 576 ? '80%' : window.innerWidth <= 576 ? '90%' : '60%';
    },
    handleResize() {
      this.dialogWidth = this.getDialogWidth();
    },
    
  },
}
</script>

<style>

</style>