<template>
<div class="sm:block hidden">
  <el-dialog v-model="errorAddDialogVisible" title="Echec d'ajout" width="40%" center :before-close="closeErrorAddModal" destroy-on-close>
    
      <el-result
        icon="error"
        title="Error Tip"
        sub-title="Please follow the instructions"
      >
        <template #extra>
          <el-button type="primary">Back</el-button>
        </template>
      </el-result>

    <template #footer>
      <span class="dialog-footer">
        <button @click="closeErrorAddModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Retour
        </button>
      </span>
    </template>
  </el-dialog>
</div>
<div class="sm:hidden block" >
  <el-dialog v-model="errorAddDialogVisible" title="Echec d'ajout" width="85%" center :before-close="closeErrorAddModal" destroy-on-close>
    
      <el-result
        icon="error"
        title="Error Tip"
        sub-title="Please follow the instructions"
      >
        <template #extra>
          <el-button type="primary">Back</el-button>
        </template>
      </el-result>

    <template #footer>
      <span class="dialog-footer">
        <button @click="closeErrorAddModal" class="py-2 px-4 mx-1 sm:my-0 my-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
          Retour
        </button>
      </span>
    </template>
  </el-dialog>
</div>
</template>

<script>

export default {
  
  props:{
    errorAddDialogVisible : Boolean,
    closeErrorAddModal: Function,
  },

}
</script>

<style>

</style>