<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_39_2158)">
        <path d="M7 6.63086H6C5.46957 6.63086 4.96086 6.84157 4.58579 7.21665C4.21071 7.59172 4 8.10043 4 8.63086V17.6309C4 18.1613 4.21071 18.67 4.58579 19.0451C4.96086 19.4201 5.46957 19.6309 6 19.6309H15C15.5304 19.6309 16.0391 19.4201 16.4142 19.0451C16.7893 18.67 17 18.1613 17 17.6309V16.6309" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.385 6.21597C20.7788 5.82212 21.0001 5.28795 21.0001 4.73097C21.0001 4.17398 20.7788 3.63981 20.385 3.24597C19.9912 2.85212 19.457 2.63086 18.9 2.63086C18.343 2.63086 17.8088 2.85212 17.415 3.24597L9 11.631V14.631H12L20.385 6.21597Z" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 4.63086L19 7.63086" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_39_2158">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>