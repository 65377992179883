<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7_18916)">
        <path d="M3 12.6307C3 13.8126 3.23279 14.983 3.68508 16.0749C4.13738 17.1668 4.80031 18.159 5.63604 18.9947C6.47177 19.8304 7.46392 20.4934 8.55585 20.9457C9.64778 21.3979 10.8181 21.6307 12 21.6307C13.1819 21.6307 14.3522 21.3979 15.4442 20.9457C16.5361 20.4934 17.5282 19.8304 18.364 18.9947C19.1997 18.159 19.8626 17.1668 20.3149 16.0749C20.7672 14.983 21 13.8126 21 12.6307C21 10.2438 20.0518 7.9546 18.364 6.26678C16.6761 4.57895 14.3869 3.63074 12 3.63074C9.61305 3.63074 7.32387 4.57895 5.63604 6.26678C3.94821 7.9546 3 10.2438 3 12.6307Z" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 9.63074H12.01" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 12.6307H12V16.6307H13" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_7_18916">
        <rect width="24" height="24" fill="white" transform="translate(0 0.630737)"/>
        </clipPath>
        </defs>
    </svg>
</template>