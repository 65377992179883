<template>
<div class="sm:block hidden">
  <el-dialog v-model="pauseSessionModal" title="Série en pause" width="40%" center :before-close="closeModal" destroy-on-close>
    <div @click="closeModal" class="flex justify-center w-full text-center mt-2 transform hover:text-red-400 hover:scale-110">
       <svg class="m-0 p-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="100" height="100" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
    </div>
    
    <template #footer>
      <span class="dialog-footer">
        
      </span>
    </template>
  </el-dialog>
</div>
<div class="sm:hidden block">
  <el-dialog v-model="pauseSessionModal" title="Série en pause" width="80%" center :before-close="closeModal" destroy-on-close>
    <div @click="closeModal" class="flex justify-center w-full text-center mt-2 transform hover:text-red-400 hover:scale-110">
       <svg class="m-0 p-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="100" height="100" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
    </div>
    
    <template #footer>
      <span class="dialog-footer">
        
      </span>
    </template>
  </el-dialog>
</div>
  
</template>

<script>
import {VideoPlay} from '@element-plus/icons';
export default {
  components: {
    VideoPlay,
  },
  data () {
    return {

    }
  },
  
  props:{
    pauseSessionModal : Boolean,
    closePauseSession: Function,
  },

  methods: {
    closeModal(){
      this.closePauseSession()
    }, 
    
  },
}
</script>

<style>

</style>