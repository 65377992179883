<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7_22436)">
        <path d="M17.4792 10.0721C17.3616 8.49848 16.7508 7.00204 15.7335 5.79574C14.7162 4.58943 13.3444 3.73471 11.8132 3.35322C10.282 2.97173 8.66953 3.0829 7.2052 3.67092C5.74087 4.25894 4.49932 5.29384 3.65722 6.62835C2.81513 7.96285 2.41541 9.52896 2.51494 11.1038C2.61447 12.6786 3.20819 14.182 4.21159 15.3998C5.215 16.6177 6.57697 17.4881 8.10368 17.887C9.63039 18.286 11.244 18.1933 12.715 17.6221" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 6.46405V10.6307L11.6667 12.2974" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.8333 18.9641V18.9724" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.8333 16.4641C16.2069 16.4629 16.5693 16.3364 16.8625 16.1049C17.1557 15.8733 17.3627 15.5501 17.4505 15.187C17.5382 14.8239 17.5016 14.4418 17.3465 14.1019C17.1914 13.7621 16.9268 13.4841 16.595 13.3124C16.2635 13.1426 15.8843 13.0899 15.519 13.163C15.1538 13.2361 14.824 13.4306 14.5833 13.7149" stroke="currentColor" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_7_22436">
        <rect width="20" height="20" fill="white" transform="translate(0 0.630737)"/>
        </clipPath>
        </defs>
    </svg>
</template>