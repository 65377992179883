<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_39_2112)">
        <path d="M7 5.63086C5.78639 6.65563 4.91697 8.02857 4.50943 9.5638C4.10189 11.099 4.17592 12.7224 4.7215 14.2142C5.26708 15.7059 6.25786 16.994 7.55971 17.9041C8.86156 18.8142 10.4116 19.3022 12 19.3022C13.5884 19.3022 15.1384 18.8142 16.4403 17.9041C17.7421 16.994 18.7329 15.7059 19.2785 14.2142C19.8241 12.7224 19.8981 11.099 19.4906 9.5638C19.083 8.02857 18.2136 6.65563 17 5.63086" stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 3.63086V11.6309" stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_39_2112">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>